import * as THREE from 'three';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';
import Html from './component/Html/Html';
import Background from './component/Background/Background';
import MainVideoText from './component/MainVideoText/MainVideoText';
import Loading from './component/Loading/Loading';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from './constants/routes.constants';
import Works from './component/Works/Works';
import { WORKSCONST } from './constants/works.constant';

import { MAINCONST } from './constants/works.constant';

import { Cursor } from './component/Cursor/Cursor';
import { ScrollContext } from './Context/ScrollContext';
import gsap from 'gsap';
import {SplitText} from "./libs/SplitText";
import { Helmet } from 'react-helmet';
import useCaseIndexInfo from './stores/CaseIndexStore';


gsap.registerPlugin(SplitText);


function CameraMove({ clicked, showCases, mediaQuery}) {
  const [vec] = useState(() => new THREE.Vector3(0, 0, 10));
  const [lerpPower, setLerpPower] = useState(0.05);
  const scene = useThree();

  useEffect(() => {
    if (showCases || (mediaQuery !== 'desktop' && mediaQuery !== 'laptop' && mediaQuery !== 'ipad')) {
      gsap.to(scene.camera.position, { x: 0, y: 0, duration: 0.5 });
      setLerpPower(0);
    } else {
      setLerpPower(0.05);
    }
  }, [showCases, mediaQuery]);
  return useFrame((state) => {
    if (clicked) {
      state.camera.position.lerp(vec.set(state.mouse.x * 3 / 10, state.mouse.y * 1 / 10, 10), lerpPower);
      state.camera.lookAt(0, 0, 5);
    }
  });
}

const getCurrentQuery = () => {
  if (window.innerWidth >= 1920) {
    return 'desktop';
  }
  if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
    return 'laptop';
  }
  if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
    return 'iPad';
  }
  if (window.innerWidth < 1024 && window.innerHeight < 500) {
    return 'phoneLandscape';
  }

  if (window.innerWidth >= 767 && window.innerWidth < 1024) {
    return 'iPadMini';
  }

  if (window.innerWidth < 767) {
    return 'phone';
  }
};

export default function App() {
  const [clicked, setClicked] = useState(false);
  const [ready, setReady] = useState(0);
  const [mainTextTransition, setMainTextTransition] = useState(false);
  const [casesSwitchTransition, setCasesSwitchTransition] = useState(true);
  const [videosMainText, setVideosMainText] = useState(null);
  const [videosWorks, setVideosWorks] = useState(null);
  const [showCases, setShowCases] = useState(false);
  const [backgroundRef, setBackgroundRef] = useState(null);
  const [playAudio, setPlayAudio] = useState(false);
  const [scrollContext, setScrollContext] = useState({ scrollTop: 0, viewPortHeight: 0, scrollAll: 0 });
  const [scrollContainer, setScrollContainer] = useState(null);
  const [cursorState, setCursorState] = useState(null);
  const [audio] = useState(() => Object.assign(document.createElement('audio'), {
    src: './audio/ambient.mp3',
    loop: true
  }));
  const [mediaQuery, setMediaQuery] = useState(() => getCurrentQuery());
  const [switchPage, setSwitchPage] = useState(false);
  // const [workIndex, setWorkIndex] = useState(() => getWorkIndex());

  const {setCaseWorksIndex} = useCaseIndexInfo()

  const store = { clicked, setClicked, ready, setReady, switchPage, setSwitchPage };
  const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  function setWorkIndex() {
    const loco = window.location.href.split('/');
    const current = loco[loco.length - 1];
    if (current !== '' && current !== 'works' && current !== 'contact') {
      setCaseWorksIndex(WORKSCONST.indexOf(WORKSCONST.find((el, index) => el.pathName === current)))
      return
    }
    setCaseWorksIndex(0)

  }

  useEffect(() => {
    setWorkIndex()
  }, []);

  useEffect(() => {
    if (playAudio) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [playAudio]);

  const loadVideos = async () => {
    const videosMainPageUrls = [];
    const videosWorksUrls = [];
    const percentIncreasing = Math.ceil(100 / (WORKSCONST.length + MAINCONST.length));

    const fetchVideo = async (url, index, videosArray) => {
      const response = await fetch(url);
      const blob = await response.blob();
      videosArray[index] = URL.createObjectURL(blob);
      setReady(prevPercent => prevPercent + percentIncreasing);
    };

    const fetchVideos = async () => {
      const fetchPromises = [
        ...WORKSCONST.map(async (work, index) => {
          const videoUrl = (window.innerWidth <= 500 && isCurrentDeviceMobile()) ? work.videoWorksUrlMobile : work.videoWorksUrl;
          await fetchVideo(videoUrl + '.mp4', index, videosWorksUrls);
        }),
        ...MAINCONST.map(async (work, index) => {

          const videoUrl = (window.innerWidth <= 500 && isCurrentDeviceMobile()) ? work.videoUrlMobile : work.videoUrl;
          await fetchVideo(videoUrl + '.mp4', index, videosMainPageUrls);
        })
      ];

      await Promise.all(fetchPromises);
    };

    await fetchVideos();
    setVideosMainText(videosMainPageUrls);
    setVideosWorks(videosWorksUrls);
  };

  useEffect(() => {
    loadVideos();
    const currentLocation = window.location.href.split('/').pop();
    if (currentLocation !== '' && currentLocation !== 'works') {
      setShowCases(true);
    }
  }, []);



  const handleResize = useCallback(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setWindowDimensions({ width, height });
    setMediaQuery(getCurrentQuery());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);


  // function preloadImage(url, callback) {
  //   let img = new Image();
  //   img.src = url;
  //   img.alt = '';
  //   img.onload = callback;
  // }


  const isCurrentDeviceMobile = () => {
    const details = navigator.userAgent;
    const regexp = /android|iphone|kindle|ipad/i;
    return regexp.test(details);
  };

  // function preloadImages(urls, allImagesLoadedCallback) {
  //   let loadedCounter = 0;
  //   let toBeLoadedNumber = urls.length;
  //   const imgFormat = isCurrentDeviceMobile() ? '.webp' : '.png';
  //
  //   urls.forEach(function(url) {
  //     preloadImage(url, function() {
  //       loadedCounter++;
  //       if (loadedCounter === toBeLoadedNumber) {
  //         allImagesLoadedCallback();
  //       }
  //     });
  //   });

  //   function preloadImage(url, anImageLoadedCallback) {
  //     let img = new Image();
  //     img.onload = anImageLoadedCallback;
  //     img.src = url + imgFormat;
  //     img.alt = '';
  //   }
  // }

  useEffect(()=> {
    console.log(switchPage, 'sss');
  }, [switchPage])


  console.log('AAAA')

  return (
    <>
      {/*<RedirectOverlay/>*/}
      <Helmet>
        <title>Third Dimension Studio - We help innovative companies deliver value and win users' attention</title>

        <meta name='description'
              content='Turning user engagement into sales growth for creative and innovative industries. Websites with 3d models and 3d motion graphics' />

      </Helmet>
      <ScrollContext.Provider value={[scrollContext, setScrollContext, scrollContainer, setScrollContainer]}>
        <Canvas dpr={[1, 1.5]} gl={{
          alpha: false,
          antialias: true,
        }} style={{ position: `fixed` }}>
          <PerspectiveCamera far={100} position-z={10} fov={20} makeDefault>
            <Background windowDimensions={windowDimensions} setBackgroundRef={setBackgroundRef} />
          </PerspectiveCamera>
          <CameraMove mediaQuery={mediaQuery} clicked={clicked} showCases={showCases} />
          <Routes>
            <Route path={ROUTES.Works} element={<>
              {clicked ? <Suspense fallback={null}>
                <Works videoUrls={videosWorks} showCases={showCases} setShowCases={setShowCases}
                       mediaQuery={mediaQuery}
                       switchPage={switchPage}
                       transition={casesSwitchTransition}
                       setTransition={setCasesSwitchTransition}
                       {...store} />
              </Suspense> : null}
            </>
            } />
            <Route path='/contact' element={null} />

            <Route path={ROUTES.Main} element={
              <Suspense fallback={null}>
                {clicked ?
                  <MainVideoText videoUrls={videosMainText}
                                 backgroundRef={backgroundRef}
                                 setShowCases={setShowCases}
                                 setCursorState={setCursorState}
                                 mainTextTransition={mainTextTransition}
                                 switchPage={switchPage}
                                 setSwitchPage={setSwitchPage}
                                 mediaQuery={mediaQuery}
                                 {...store}
                                 setMainTextTransition={setMainTextTransition}
                                 windowDimensions={windowDimensions} />
                  : null}
              </Suspense>

            } />
          </Routes>
        </Canvas>
        <Cursor cursorState={cursorState} setCursorState={setCursorState} />
        <Html mainTextTransition={mainTextTransition}
                         videoUrls={videosWorks}
                         setCursorState={setCursorState}
                         mediaQuery={mediaQuery}
                         audio={audio}
                         clicked={clicked}
                         playAudio={playAudio}
                         setPlayAudio={setPlayAudio}
                         transition={casesSwitchTransition}
                         setTransition={setCasesSwitchTransition}
                         showCases={showCases}
                         setShowCases={setShowCases}
                         {...store} />
          {!clicked ? <Loading setCursorState={setCursorState} setPlayAudio={setPlayAudio}  {...store} /> : null}
      </ScrollContext.Provider>
    </>
  );
}
