export const WORKSCONST = [

    // {
    //     name: 'Headphones',
    //     pathName: 'headphones',
    //     description: '3D headphones',
    //     bgColor: '#4b8d64',
    //     bgSecondColor: '#11454a',
    //     tagline: 'GROW BRAND AWARENESS',
    //     videoUrl: './videos/headphones_bg_op2',
    //     videoWorksUrl: './videos/headphones_works_op2',
    //     worksTitle: 'Product interactive landing page',
    //     servicesList: ['Creative Design', 'Web Design', 'WebGL Development']
    // },




    {
        name: 'Empathy Labs',
        pathName: 'empathy-labs',
        description: '',
        bgColor: '#788AB3',
        bgSecondColor: '#1c264e',
        tagline: 'ACCELERATE SALES FUNNEL',
        videoWorksUrl: './videos/empathy_works_2_op2',
        videoWorksUrlMobile: './videos/empathy_works_2_op2_m',
        worksTitle: '3D animated landing page for AI development service provider based in UK',
        servicesList: ['Branding', 'Creative Production', '3D Motion Design', 'WebGL Development']
    },

    {
        name: 'Hailstone Labs',
        pathName: 'hailstone-labs',
        description: '',
        bgColor: '#1D8B97',
        bgSecondColor: '#09262b',
        tagline: 'GROW BRAND AWARENESS',
        videoWorksUrl: './videos/hailstone_works_2_op2',
        videoWorksUrlMobile: './videos/hailstone_works_2_op2_m',
        worksTitle: 'Immersive landing page for Web3 VC studio from Hong Kong',
        servicesList: ['Branding', '3D Motion Design', 'WebGL Development']
    },

    {
        name: 'Miller Panelling',
        pathName: 'miller-panelling',
        description: '',
        bgColor: '#80887b',
        bgSecondColor: '#474646',
        tagline: 'BOOST USER ENGAGEMENT',
        videoWorksUrl: './videos/miller_works_2_op2',
        videoWorksUrlMobile: './videos/miller_works_2_op2_m',
        worksTitle: 'Emotion-driven website for b2b service provider working across the USA',
        servicesList: ['3D Production', 'Web Design', 'CMS Development']
    },

    {
        name: 'PB Media',
        pathName: 'pb-media',
        description: '',
        bgColor: '#544385',
        bgSecondColor: '#272755',
        tagline: 'ACCELERATE SALES FUNNEL',
        videoWorksUrl: './videos/pbmedia_works_op2',
        videoWorksUrlMobile: './videos/pbmedia_works_op2_m',
        worksTitle: 'Delivering the brand that stands out',
        servicesList: ['Branding', 'Mascot Production', '3D Motion Design', 'WebGL + Webflow Development']
    },

    {
        name: 'All In Motion',
        pathName: 'all-in-motion',
        description: '',
        bgColor: '#446bbf',
        bgSecondColor: '#1d2859',
        tagline: 'BOOST USER ENGAGEMENT',
        videoWorksUrl: './videos/allinmotion_works_op2',
        videoWorksUrlMobile: './videos/allinmotion_works_op2_m',
        worksTitle: 'Animated website for motion design studio with a twist',
        servicesList: ['Creative Design', 'Motion Design', 'Webflow Development']
    },



    {
        name: 'Byte Trading',
        pathName: 'byte',
        description: 'Revolutionary 3D product landing page',
        bgColor: '#a98abd',
        bgSecondColor: '#41394c',
        tagline: 'ACCELERATE SALES FUNNEL ',
        videoWorksUrl: './videos/byte_works_op2',
        videoWorksUrlMobile: './videos/byte_works_op2_m',
        worksTitle: 'Immersive scrollytelling and company presentation',
        servicesList: ['Creative production', 'WebGL Development']
    },



    {
        name: 'Somvai',
        pathName: 'somvai',
        description: '',
        bgColor: '#BCB3AF',
        bgSecondColor: '#a19594',
        tagline: 'GROW BRAND AWARENESS',
        videoWorksUrl: './videos/somvai_works_op2',
        videoWorksUrlMobile: './videos/somvai_works_op2_m',
        worksTitle: 'Brand-focused website for the startup launching Kickstarter campaign',
        servicesList: ['Illustrations', 'Motion Graphics', 'Development']
    },

    {
        name: 'Altra',
        pathName: 'altra',
        description: 'Interactive explainers',
        bgColor: '#94ac78',
        bgSecondColor: '#353e28',
        tagline: 'ACCELERATE SALES FUNNEL',
        videoWorksUrl: './videos/altra_works_op2',
        videoWorksUrlMobile: './videos/altra_works_op2_m',
        worksTitle: 'Interactive city for MVP launch by Coinspaid',
        servicesList: ['Creative Design' , 'Web Design' , 'WebGL Development']
    },

    {
        name: 'Corvus Research',
        pathName: 'corvus',
        description: '3D interactive landing page',
        bgColor: '#989898',
        bgSecondColor: '#3e3e3e',
        tagline: 'GROW BRAND AWARENESS',
        videoWorksUrl: './videos/corvus_works_op2',
        videoWorksUrlMobile: './videos/corvus_works_op2_m',
        worksTitle: 'Innovative presence and HR brand enhancement for AI research company',
        servicesList: ['Creative Design',' Web Design', 'WebGL Development']
    },

    {
        name: 'Third Dimension 3D City',
        pathName: 'city',
        description: '3D interactive landing',
        bgColor: '#4b8d64',
        bgSecondColor: '#11454a',
        tagline: 'BOOST USER ENGAGEMENT',
        videoWorksUrl: './videos/city_works_op2',
        videoWorksUrlMobile: './videos/city_works_op2_m',
        worksTitle: 'Enractive explainer on how create 3d websites',
        servicesList: ['Creative Design', 'Web Design', 'WebGL Development']
    },







]

export const MAINCONST = [

    // {
    //     name: 'Headphones',
    //     pathName: 'headphones',
    //     description: '3D headphones',
    //     bgColor: '#4b8d64',
    //     bgSecondColor: '#11454a',
    //     tagline: 'GROW BRAND AWARENESS',
    //     videoUrl: './videos/headphones_bg_op2',
    //     videoWorksUrl: './videos/headphones_works_op2',
    //     worksTitle: 'Product interactive landing page',
    //     servicesList: ['Creative Design', 'Web Design', 'WebGL Development']
    // },




    {
        name: 'Empathy Labs',
        pathName: 'empathy-labs',
        description: '',
        bgColor: '#788AB3',
        bgSecondColor: '#1c264e',
        tagline: 'ACCELERATE SALES FUNNEL',
        videoUrl: './videos/empathy_bg_op2',
        videoUrlMobile: './videos/empathy_bg_op2_m',
        worksTitle: '3D animated landing page for AI development service provider based in UK',
        servicesList: ['Branding', 'Creative Production', '3D Motion Design', 'WebGL Development']
    },

    {
        name: 'Hailstone Labs',
        pathName: 'hailstone-labs',
        description: '',
        bgColor: '#1D8B97',
        bgSecondColor: '#09262b',
        tagline: 'GROW BRAND AWARENESS',
        videoUrl: './videos/hailstone_bg_op2',
        videoUrlMobile: './videos/hailstone_bg_op2_m',
        worksTitle: 'Immersive landing page for Web3 VC studio from Hong Kong',
        servicesList: ['Branding', '3D Motion Design', 'WebGL Development']
    },

    {
        name: 'Miller Panelling',
        pathName: 'miller-panelling',
        description: '',
        bgColor: '#80887b',
        bgSecondColor: '#474646',
        tagline: 'BOOST USER ENGAGEMENT',
        videoUrl: './videos/miller_bg_op2',
        videoUrlMobile: './videos/miller_bg_op2_m',
        worksTitle: 'Emotion-driven website for b2b service provider working across the USA',
        servicesList: ['3D Production', 'Web Design', 'CMS Development']
    },

    {
        name: 'PB Media',
        pathName: 'pb-media',
        description: '',
        bgColor: '#544385',
        bgSecondColor: '#272755',
        tagline: 'GROW BRAND AWARENESS',
        videoUrl: './videos/pbmedia_bg_op2',
        videoUrlMobile: './videos/pbmedia_bg_op2_m',
        worksTitle: 'Delivering the brand that stands out',
        servicesList: ['Branding', 'Mascot Production', '3D Motion Design', 'WebGL + Webflow Development']
    },







]
