import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { WORKSCONST } from '../../constants/works.constant';
import { ScrollControls, useTexture } from '@react-three/drei';
import gsap from 'gsap';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { useNavigate } from 'react-router';
import Power3 from 'gsap';
import { Routes, Route, useLocation } from 'react-router-dom';
import CasesGLController from '../Cases/CasesGLController';
import { ScrollContext } from '../../Context/ScrollContext';
import { sRGBEncoding } from '@react-three/drei/helpers/deprecated';
import useCaseIndexInfo from '../../stores/CaseIndexStore';

export default function Works(props) {
  const firstPlane = useRef();
  const secondPlane = useRef();

  const {caseWorksIndex , setCaseWorksIndex} = useCaseIndexInfo()

  const [previousIndex, setPreviousIndex] = useState(caseWorksIndex);
  const [firstPlanMesh, setFirstPlanMesh] = useState(firstPlane);
  const [secondPlanMesh, setSecondPlanMesh] = useState(secondPlane);
  // const [setScrollContext, scrollContainer] = useContext(ScrollContext);

  const positionsQuery = {
    'desktop': {
      positionXStatic: 0.6,
      positionXFadeOut: 0,
      positionXFadeIn: 2.0,
      positionZStatic: 4.5,
      positionZFadeOut: 10,
      positionZFadeIn: -1.5,
      positionY: -0.065
    },
    'laptop': {
      positionXStatic: 0.4,
      positionXFadeOut: 0,
      positionXFadeIn: 1.7,
      positionZStatic: 4.5,
      positionZFadeOut: 9,
      positionZFadeIn: -1.52,
      positionY: -0.05
    },
    'iPad': {
      positionXStatic: 0.6,
      positionXFadeOut: 0,
      positionXFadeIn: 1.5,
      positionZStatic: 3,
      positionZFadeOut: 8,
      positionZFadeIn: -1.5,
      positionY: 0
    },
    'iPadMini': {
      positionXStatic: 0,
      positionXFadeOut: 0,
      positionXFadeIn: 1.3,
      positionZStatic: 1,
      positionZFadeOut: 7,
      positionZFadeIn: -1.5,
      positionY: 0.3
    },
    'phone': {
      positionXStatic: 0,
      positionXFadeOut: 0,
      positionXFadeIn: 0.5,
      positionZStatic: -1,
      positionZFadeOut: 6,
      positionZFadeIn: -5,
      positionY: 0.5
    },
    'phoneLandscape': {
      positionXStatic: 0.5,
      positionXFadeOut: 0,
      positionXFadeIn: 1,
      positionZStatic: 5,
      positionZFadeOut: 8,
      positionZFadeIn: -1,
      positionY: 0
    }
  };
  const navigate = useNavigate();
  const alphaTexture = useTexture('./alphaTextWorks_2.jpg');
  const location = useLocation();
  const [videoFirst] = useState(() => Object.assign(document.createElement('video'), {
    src: props.videoUrls[caseWorksIndex],
    crossOrigin: 'Anonymous',
    loop: true,
    playsInline: true,
    muted: true
  }));
  const [videoSecond] = useState(() => Object.assign(document.createElement('video'), {
    src: props.videoUrls[caseWorksIndex],
    crossOrigin: 'Anonymous',
    playsInline: true,
    loop: true,
    muted: true
  }));

  useEffect(() => {
    const locationSeparated = location.pathname.split('/');
    const currentLocation = locationSeparated[locationSeparated.length - 1];
    if (currentLocation === 'works') {
      props.setTransition(true);
      props.setShowCases(false);
      secondPlanMesh.current.position.x = positionsQuery[props.mediaQuery].positionXFadeIn;
      secondPlanMesh.current.position.z = positionsQuery[props.mediaQuery].positionZFadeIn;
      secondPlanMesh.current.position.y = positionsQuery[props.mediaQuery].positionY;
      firstPlanMesh.current.position.y = positionsQuery[props.mediaQuery].positionY;
      gsap.fromTo(firstPlanMesh.current.position,
        {
          x: positionsQuery[props.mediaQuery].positionXFadeIn,
          z: positionsQuery[props.mediaQuery].positionZFadeIn,
          duration: 1.5,
          delay: 0.5,
          ease: Power3.easeInOut
        },
        {
          x: positionsQuery[props.mediaQuery].positionXStatic,
          z: positionsQuery[props.mediaQuery].positionZStatic,
          duration: 1.0,
          delay: 0.2,
          ease: Power3.easeInOut
        });
      gsap.to(firstPlanMesh.current.rotation, { y: -0.35, duration: 1.5, delay: 0.5, ease: Power3.easeInOut });
      for (let mesh of firstPlanMesh.current.children) {
        gsap.fromTo(mesh.material, { opacity: 0 }, {
          opacity: 1,
          duration: 1.5,
          delay: 0.5,
          ease: Power3.easeInOut
        });
      }
    }
    if (currentLocation !== '' && currentLocation !== 'works') {

      const workIndex = WORKSCONST.indexOf(WORKSCONST.find((el, index) => el.pathName === currentLocation))
      setCaseWorksIndex(workIndex)
      // props.setWorkIndex(workIndex);
      for (let mesh of firstPlanMesh.current.children) {
        mesh.material.opacity = 1;
      }


      setTimeout(() => {
        props.setTransition(false);
      }, 500);
    }
  }, [location]);


  const switchPlanes = () => {


    let firstPlanM;
    let firstPlanVideo;
    let secondPlanM;
    let secondPlanVideo;
    if (firstPlanMesh === firstPlane) {
      firstPlanM = firstPlane;
      firstPlanVideo = videoFirst;
      secondPlanM = secondPlane;
      secondPlanVideo = videoSecond;
    } else if (firstPlanMesh === secondPlane) {
      firstPlanM = secondPlane;
      firstPlanVideo = videoSecond;
      secondPlanM = firstPlane;
      secondPlanVideo = videoFirst;
    }
    secondPlanVideo.src = props.videoUrls[caseWorksIndex];
    secondPlanVideo.play().then(()=> {
      gsap.fromTo(firstPlanM.current.position, {
        x: positionsQuery[props.mediaQuery].positionXStatic,
        z: positionsQuery[props.mediaQuery].positionZStatic
      }, {
        x: positionsQuery[props.mediaQuery].positionXFadeOut,
        z: positionsQuery[props.mediaQuery].positionZFadeOut,
        duration: 1.5
      }).then(() => {
        firstPlanM.current.position.x = positionsQuery[props.mediaQuery].positionXFadeIn;
        firstPlanM.current.position.z = positionsQuery[props.mediaQuery].positionZFadeIn;
        firstPlanVideo.pause();
      });

      for (let mesh of firstPlanM.current.children) {
        gsap.to(mesh.material, { opacity: 0, duration: 1.5 });
      }

      gsap.fromTo(secondPlanM.current.position, {
        x: positionsQuery[props.mediaQuery].positionXFadeIn,
        z: positionsQuery[props.mediaQuery].positionZFadeIn,
        duration: 1.5
      }, {
        x: positionsQuery[props.mediaQuery].positionXStatic,
        z: positionsQuery[props.mediaQuery].positionZStatic,
        duration: 1.5
      });

      for (let mesh of secondPlanM.current.children) {
        gsap.to(mesh.material, { opacity: 1, duration: 1.5 });
      }

      if (firstPlanMesh === firstPlane) {
        setFirstPlanMesh(secondPlane);
        setSecondPlanMesh(firstPlane);

      } else {
        setFirstPlanMesh(firstPlane);
        setSecondPlanMesh(secondPlane);
      }
    });
  };


  const switchCases = () => {
    let firstPlanM;
    let firstPlanVideo;
    let secondPlanM;
    let secondPlanVideo;
    if (firstPlanMesh === firstPlane) {
      firstPlanM = firstPlane;
      firstPlanVideo = videoFirst;
      secondPlanM = secondPlane;
      secondPlanVideo = videoSecond;
    } else if (firstPlanMesh === secondPlane) {
      firstPlanM = secondPlane;
      firstPlanVideo = videoSecond;
      secondPlanM = firstPlane;
      secondPlanVideo = videoFirst;
    }
    // secondPlanVideo.src = props.videoUrls[caseWorksIndex];
    // secondPlanVideo.play().then(()=> {

      // scrollContainer.scrollTop = 0;
      // setScrollContext({scrollTop: 0, viewPortHeight: 0, scrollAll: 0})

      // scrollContainer.scrollTop = 0;


      firstPlanM.current.position.x = positionsQuery[props.mediaQuery].positionXFadeIn;
      firstPlanM.current.position.z = positionsQuery[props.mediaQuery].positionZFadeIn;
      for (let mesh of firstPlanMesh.current.children) {
        mesh.material.opacity = 0;
      }


      for (let mesh of secondPlanM.current.children) {
        mesh.material.opacity = 1;
      }

      secondPlanMesh.current.position.x = positionsQuery[props.mediaQuery].positionXStatic;
    secondPlanMesh.current.position.z = positionsQuery[props.mediaQuery].positionZStatic;
    secondPlanMesh.current.position.y = positionsQuery[props.mediaQuery].positionY;



    secondPlanVideo.src = props.videoUrls[caseWorksIndex]

    secondPlanVideo.play()

      if (caseWorksIndex < WORKSCONST.length - 1) {
        firstPlanVideo.src = props.videoUrls[caseWorksIndex + 1];
      } else {
        firstPlanVideo.src = props.videoUrls[0];
      }
      firstPlanVideo.play()


      firstPlanM.current.position.y = positionsQuery[props.mediaQuery].positionY;
      firstPlanM.current.rotation.y = -0.35;

      if (firstPlanMesh === firstPlane) {
        setFirstPlanMesh(secondPlane);
        setSecondPlanMesh(firstPlane);
      } else {
        setFirstPlanMesh(firstPlane);
        setSecondPlanMesh(secondPlane);
      }
  };


  useEffect(() => {
    videoFirst.play();
  }, []);

  useEffect(() => {
    if (props.showCases) {
      let secondPlanVideo;
      if (firstPlanMesh === firstPlane) {
        secondPlanVideo = videoSecond;
      } else if (firstPlanMesh === secondPlane) {
        secondPlanVideo = videoFirst;
      }

      // gsap.to(scene.camera.position, { x: 0, y: 0, duration: 0.5 });
      if (caseWorksIndex < WORKSCONST.length - 1) {
        secondPlanVideo.src = props.videoUrls[caseWorksIndex + 1];
      } else {
        secondPlanVideo.src = props.videoUrls[0];
      }
      secondPlanVideo.play()

    }
  }, [props.showCases]);


  useEffect(() => {
    if (props.switchPage) {
      for (let mesh of firstPlane.current.children) {
        gsap.to(mesh.material, { opacity: 0, duration: 1 });
      }
      for (let mesh of secondPlane.current.children) {
        gsap.to(mesh.material, { opacity: 0, duration: 1 });
      }
    }
  }, [props.switchPage]);


  useEffect(() => {
    if (caseWorksIndex !== previousIndex) {
      if (!props.showCases) {
        switchPlanes();
      } else {
        switchCases();
      }
      setPreviousIndex(caseWorksIndex);
    }

  }, [caseWorksIndex]);


  const nextCase = () => {

    for (let mesh of firstPlanMesh.current.children) {
      mesh.material.opacity = 0;
    }


      if (caseWorksIndex < WORKSCONST.length - 1) {

        console.log(caseWorksIndex, 'SSSS');

        navigate(WORKSCONST[caseWorksIndex + 1].pathName);
      } else {
        navigate(WORKSCONST[0].pathName);
      }
  };


  return (
    <>
      <group ref={firstPlane} position-x={positionsQuery[props.mediaQuery].positionXStatic}
             position-y={positionsQuery[props.mediaQuery].positionY}
             position-z={positionsQuery[props.mediaQuery].positionZStatic} rotation-y={-0.35}>
        <mesh scale={[1.8, 1, 1]}>
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial transparent={true} opacity={0}>
            <videoTexture encoding={sRGBEncoding} attach='map'
                          args={[videoFirst]} />
          </meshBasicMaterial>
        </mesh>
        <mesh position-y={-1.025} position-z={0} rotation-x={Math.PI} scale={[1.8, 1, 1]}>
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial alphaMap={alphaTexture} side={THREE.BackSide} transparent={true}
                             opacity={0}>
            <videoTexture encoding={sRGBEncoding} attach='map'
                          args={[videoFirst]} />
          </meshBasicMaterial>
        </mesh>
      </group>
      <group ref={secondPlane} position-x={positionsQuery[props.mediaQuery].positionXStatic}
             position-y={positionsQuery[props.mediaQuery].positionY}
             position-z={positionsQuery[props.mediaQuery].positionZStatic} rotation-y={-0.35}>
        <mesh scale={[1.8, 1, 1]}>
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial transparent={true} opacity={0}>
            <videoTexture encoding={sRGBEncoding} transparent={true} attach='map'
                          args={[videoSecond]} />
          </meshBasicMaterial>
        </mesh>
        <mesh position-y={-1.025} position-z={0} rotation-x={Math.PI} scale={[1.8, 1, 1]}>
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial alphaMap={alphaTexture} side={THREE.BackSide} transparent={true}
                             opacity={0}>
            <videoTexture encoding={sRGBEncoding} transparent={true} attach='map'
                          args={[videoSecond]} />
          </meshBasicMaterial>
        </mesh>
      </group>
      <Routes>
        {WORKSCONST.map((item, index) => <Route key={index} path={'/' + item.pathName}
                                                element={
                                                  <CasesGLController nextCase={nextCase}
                                                                     setTransition={props.setTransition}
                                                                     positionsQuery={positionsQuery}
                                                                     firstPlanMesh={firstPlanMesh}
                                                                     secondPlanMesh={secondPlanMesh}
                                                                     backgroundRef={props.backgroundRef}
                                                                     switchPage={props.switchPage}
                                                                     mediaQuery={props.mediaQuery}
                                                                     // casesImages={props.casesImages}
                                                                     setShowCases={props.setShowCases}
                                                                     videoUrls={props.videoUrls} workIndex={index}
                                                                     transition={props.transition} />}
        />)}
      </Routes>
    </>
  );
}