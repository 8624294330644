import React, { useCallback, useContext, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import './cases.css';
import gsap from 'gsap';
import { ScrollContext } from '../../Context/ScrollContext';


export default function CasesGLController(props) {
  const [scrollContext, setScrollContext, scrollContainer] = useContext(ScrollContext);
  const { height } = useThree((state) => state.viewport);


  useEffect(() => {
    props.setShowCases(true);
    props.setTransition(true);

    return () => {
      if (props.backgroundRef ) {
        gsap.to(props.backgroundRef.current, { uVisibility: 1, duration: 1.5, delay: 0.5 });
      }
      if (props.firstPlanMesh.current) {
        props.firstPlanMesh.current.scale.set(1, 1, 1);
      }
    };
  }, [props.firstPlanMesh]);

  useEffect(() => {
    if (props.switchPage) {
      for (let mesh of props.firstPlanMesh.current.children) {
        gsap.to(mesh.material, { opacity: 0, duration: 1.0 });
      }
    }
  }, [props.switchPage]);

  const getScrollRange = useCallback((from, distance, offset) => {
    const start = from;
    const end = start + distance;
    return offset < start ? 0 : offset > end ? 1 : (offset - start) / (end - start);
  }, []);

  useEffect(() => {
    const offset = scrollContext.scrollTop / scrollContext.scrollAll;
    const offsetBottom = (scrollContext.scrollTop + scrollContext.viewPortHeight) / scrollContext.scrollAll;

    const r3 = getScrollRange(0 / 20, 1 / 20, offset);
    const r4 = getScrollRange(4.5 / 7, 1 / 7, offset);
    const r5 = getScrollRange(9 / 10, 1 / 10, offsetBottom + 0.01);

    if (!props.transition && !props.switchPage) {
      props.firstPlanMesh.current.position.x = THREE.MathUtils.lerp(props.positionsQuery[props.mediaQuery].positionXStatic, 0, r3);
      props.firstPlanMesh.current.scale.set(THREE.MathUtils.lerp(1, height * 1.2, r3), THREE.MathUtils.lerp(1, height * 1.2, r3), 1);
      props.firstPlanMesh.current.position.z = THREE.MathUtils.lerp(props.positionsQuery[props.mediaQuery].positionZStatic, 0, r3);
      props.firstPlanMesh.current.position.y = THREE.MathUtils.lerp(props.positionsQuery[props.mediaQuery].positionY, 0, r3);
      props.firstPlanMesh.current.rotation.y = THREE.MathUtils.lerp(-0.35, 0, r3);

      for (let mesh of props.firstPlanMesh.current.children) {
        mesh.material.opacity = THREE.MathUtils.lerp(1, 0, r3);
      }

      if (r4 > 0) {
        props.firstPlanMesh.current.position.y = THREE.MathUtils.lerp(0, 15, r4);
      }

      for (let mesh of props.secondPlanMesh.current.children) {
        mesh.material.opacity = THREE.MathUtils.lerp(0, 1, r5);
      }
      props.secondPlanMesh.current.position.z = THREE.MathUtils.lerp(props.positionsQuery[props.mediaQuery].positionZFadeIn, props.positionsQuery[props.mediaQuery].positionZStatic, r5);
      props.secondPlanMesh.current.position.x = THREE.MathUtils.lerp(props.positionsQuery[props.mediaQuery].positionXFadeIn, props.positionsQuery[props.mediaQuery].positionXStatic, r5);

      if (offsetBottom + 0.01 >= 1 && scrollContainer.scrollTop > 0) {
        console.log('switch');
        props.setTransition(true);
        props.nextCase();

        props.firstPlanMesh.current.scale.set(1, 1, 1);
      }
    }
  }, [scrollContext, props.switchPage, getScrollRange]);

  return null;
}
