import {create} from 'zustand';

const useCaseIndexInfo = create((set) => ({

  caseWorksIndex: 0,
  setCaseWorksIndex: (caseWorksIndex = 0) => set({ caseWorksIndex }),

  caseMainIndex: 0,
  setCaseMainIndex: (caseMainIndex = 0) => set({ caseMainIndex }),

}));

export default useCaseIndexInfo;