import './works.css';
import React, { useEffect, useRef, useState } from 'react';
import { WORKSCONST } from '../../constants/works.constant';
import gsap from 'gsap';
import {SplitText} from "../../libs/SplitText";

import { useNavigate } from 'react-router';
import { Route, Routes, useLocation } from 'react-router-dom';
import CasesUI from '../Cases/CasesUi';
import { useGSAP } from '@gsap/react';
import { Helmet } from 'react-helmet';
import useCaseIndexInfo from '../../stores/CaseIndexStore';


export default function WorksUI(props) {
  const navigate = useNavigate();

  const workTitle = useRef();
  const worksTags = useRef();
  const description = useRef();
  const worksUIContainer = useRef();
  const worksScrollbar = useRef();
  const caseButton = useRef();
  const servicesTitle = useRef();
  const DELTA_SCROLL_FOR_SWICH_PAGE = 150;
  const [deltaScroll, setDeltaScroll] = useState(0);
  const [toushY, setToushY] = useState(0);
  const [scrollDelay, setScrollDelay] = useState(true);
  const {caseWorksIndex , setCaseWorksIndex} = useCaseIndexInfo()


  useEffect(() => {
    if (!props.showCases && props.clicked) {
      animateText();
    }
  }, [caseWorksIndex, props.showCases, props.clicked]);


  const animateText = () => {

    gsap.to(worksUIContainer.current, { opacity: 1, duration: 0.5 });
    // gsap.to(worksScrollbar.current, {opacity: 0, duration: 0.5})

    const title = new SplitText(workTitle.current, {
      types: 'words, chars',
      lineClass: 'horizontal-anim'
    });
    gsap.from(title.chars, {
      opacity: 0,
      duration: 1.0,
      stagger: 0.06
    })

    const textDescription = new SplitText(description.current, {
      types: 'lines, words',
      lineClass: 'word-line'
    });
    gsap.from(textDescription.words, {
      y: '75%',
      // skewX: '-3',
      opacity: 0,
      duration: 1.0,
      stagger: 0.03
    })

    // const servicesT = new SplitText(servicesTitle.current, {
    //   types: 'lines, words',
    //   lineClass: 'word-line'
    // });
    //
    // gsap.from(servicesT.words, {
    //   y: '75%',
    //   // skewX: '-3',
    //   opacity: 0,
    //   duration: 1.0,
    //   stagger: 0.03
    // })

    // const worksTagsS = new SplitText(worksTags.current, {
    //   types: 'lines, words',
    //   lineClass: 'word-line'
    // });
    // gsap.from(worksTagsS.words, {
    //   y: '100%',
    //   skewX: '-6',
    //   opacity: 0,
    //   duration: 1.5,
    //   stagger: 0.03
    // }).then(() => {
    //   worksTagsS.revert();
    // });
  };


  const animateTextFadeOut = () => {
    // servicesList.current = servicesList.current.slice(0, WORKSCONST[caseWorksIndex].servicesList.length);
    const title = new SplitText(workTitle.current, {
      types: 'words, chars',
      lineClass: 'horizontal-anim'
    });

    gsap.to(title.chars, {
      opacity: 0,
      duration: 0.5,
      stagger: 0.06
    });

    const textDescription = new SplitText(description.current, {
      types: 'lines, words',
      lineClass: 'word-line'
    });
    gsap.to(textDescription.words, {
      y: '75%',
      // skewX: '-3',
      // opacity: 0,
      duration: 0.5,
      stagger: 0.03
    });

    // const servicesT = new SplitText(servicesTitle.current, {
    //   types: 'lines, words',
    //   lineClass: 'word-line'
    // });
    // gsap.to(servicesT.words, {
    //   y: '75%',
    //   // skewX: '-3',
    //   // opacity: 0,
    //   duration: 1,
    //   stagger: 0.03
    // });

    // const worksTagsS = new SplitText(worksTags.current, {
    //   types: 'lines, words',
    //   lineClass: 'word-line'
    // });
    //
    // console.log('w');
    //
    // gsap.to(worksTags.current.children, {
    //   y: '100%',
    //   skewX: '-6',
    //   // opacity: 0,
    //   duration: 1,
    //   stagger: 0.03
    // });
  };

  useEffect(() => {
    if (!props.showCases && props.clicked) {
      document.addEventListener('wheel', onWheel);
      document.addEventListener('touchstart', onTouchStart);
      document.addEventListener('touchend', onTouchEnd);
    }
    return () => {
      document.removeEventListener('wheel', onWheel);
      document.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('touchend', onTouchEnd);
    };
  }, [deltaScroll, toushY, caseWorksIndex, scrollDelay, props.showCases, props.clicked]);

  useEffect(() => {
    if (props.switchPage) {
      gsap.to(worksUIContainer.current, { opacity: 0, duration: 0.5 });
      gsap.to(worksScrollbar.current, { opacity: 0, duration: 0.5 });
    }
  }, [props.switchPage]);

  const onTouchStart = (e) => {
    setToushY(e.touches[0].clientY);
  };

  const onTouchEnd = (e) => {
    onWheel({ deltaY: toushY - e.changedTouches[0].clientY });
  };

  useEffect(() => {
    setTimeout(() => {
      setScrollDelay(false);
    }, 1550);
    if (!props.showCases && props.clicked) {
      gsap.to(worksUIContainer.current, { opacity: 1, duration: 0.5 });
    }
  }, [props.clicked]);

  const onWheel = (e) => {
    if (!scrollDelay) {
      setDeltaScroll(deltaScroll + e.deltaY);
    }
    if (deltaScroll + e.deltaY >= DELTA_SCROLL_FOR_SWICH_PAGE && !scrollDelay) {
      if (caseWorksIndex < WORKSCONST.length - 1) {
        switchWork(caseWorksIndex + 1);
      } else {
        switchWork(0);
      }
      setDeltaScroll(0);
    }

    if (deltaScroll + e.deltaY <= -DELTA_SCROLL_FOR_SWICH_PAGE && !scrollDelay) {
      if (caseWorksIndex > 0) {
        switchWork(caseWorksIndex - 1);
      } else {
        switchWork(WORKSCONST.length - 1);
      }
      setDeltaScroll(0);
    }
  };

  const setWorkByClick = (index) => {
    if (!scrollDelay) {
      switchWork(index);
    }
  };

  const switchWork = (workIndex) => {
    setScrollDelay(true);
    gsap.to(worksUIContainer.current, { opacity: 0, duration: 0.3 }).then(() => {
      setCaseWorksIndex(workIndex);
      // props.setWorkIndex(workIndex);
      gsap.to(worksUIContainer.current, { opacity: 1, duration: 0.1 });
      setTimeout(() => {
        setScrollDelay(false);
      }, 1550);
    });
  };

  const goToCase = () => {
    if (!scrollDelay) {
      setScrollDelay(true);
      animateTextFadeOut();
      gsap.to(worksUIContainer.current, { opacity: 0, duration: 0.5 });
      gsap.to(worksScrollbar.current, { opacity: 0, duration: 0.5 });
      props.setCursorState((state) => state = '');
      setTimeout(() => {
        props.setShowCases(true);
        setScrollDelay(false);
        navigate('/works' + '/' + WORKSCONST[caseWorksIndex].pathName);
      }, 500);
    }
  };

  const onMouseOver = () => {
    props.setCursorState((state) => state = 'cursor-hover');
  };

  const onMouseOut = () => {
    props.setCursorState((state) => state = '');
  };

  useEffect(() => {
    console.log(caseWorksIndex);

  }, [caseWorksIndex]);


  return (
    <>
      <Helmet>
        <title>Showcasing Expertise in WebGL, Three.js, and 3D Graphics</title>

        <meta name='description'
              content='Explore our expertise and discover innovative websites and cutting-edge interactive 3D web experiences crafted by our team.'/>

      </Helmet>
      <h1 className={'hidden-h1'}>
      Showcasing Expertise in WebGL, Three.js, and 3D Graphics
      </h1>
      {props.showCases ? null : <>
        <div ref={worksUIContainer} className={`works_container ${props.clicked ? '' : 'no-point-event'}`} >
          <div className='works_info_container'>
            <h1 ref={workTitle} className='works_title'>{WORKSCONST[caseWorksIndex].name}</h1>
            <div className='works_info'>
              <h2 ref={description} className='works_description'>
                {WORKSCONST[caseWorksIndex].worksTitle}
              </h2>
              <button onMouseOver={onMouseOver}
                      onMouseOut={onMouseOut} className='case_button' ref={caseButton} onClick={() => {
                goToCase();
              }}>SEE CASE<img className='case_button_arrow' src='/images/arrow-right.svg' alt='' />
              </button>
            </div>
          </div>
            <div className='works_tags_container'>
                {/*<div ref={servicesTitle} className='works_tags_title'>SERVICES:</div>*/}
                <div ref={worksTags} className='works_tags'>
                  {WORKSCONST[caseWorksIndex].servicesList.map((item, index) => <div className={'works_tag'} key={index}>{item}</div>)}
                </div>
            </div>
        </div>
        <div ref={worksScrollbar} className={`scrollbar_container  ${props.clicked ? '' : 'no-point-event'}`} style={{opacity: props.clicked ? 100 : 0}}>
          {WORKSCONST.map((item, index) => <div key={index} onClick={() => {
            setWorkByClick(index);
          }} className='scrollbar_dot'>
            <div className='static' />
            <div className={caseWorksIndex === index ? 'active resized' : 'resized'} />
          </div>)}
        </div>
      </>}
      {/*<Routes>*/}
      {/*    {WORKSCONST.map((item, index) => <Route key={index} path={'/' + item.pathName}*/}
      {/*                                            element={*/}
      {/*                                                <CasesUI*/}
      {/*                                                    backgroundRef={props.backgroundRef}*/}
      {/*                                                    mediaQuery={props.mediaQuery}*/}
      {/*                                                    casesImages={props.casesImages}*/}
      {/*                                                    setShowCases={props.setShowCases}*/}
      {/*                                                    videoUrls={props.videoUrls} workIndex={index}*/}
      {/*                                                />}*/}
      {/*    />)}*/}
      {/*</Routes>*/}
    </>

  );
}