import './footer.css'
import {useEffect, useRef} from "react";
import gsap from 'gsap';
// import SplitType from 'split-type'
import {SplitText} from "../../libs/SplitText";
import { useGSAP } from '@gsap/react';



export const Footer = (props) => {

    const description = useRef();


    // useEffect(() => {
    //     const text = new SplitText(description.current, {
    //         types: "lines, words",
    //         lineClass: "word-line"
    //     });
    //     gsap.from(text.words, {
    //         y: "100%",
    //         skewX: "-3",
    //         opacity: 0,
    //         duration: 1.5,
    //         delay: 0.5,
    //         stagger: 0.03,
    //     });
    // }, [])

    useGSAP(() => {


        const text = new SplitText(description.current, {
            types: "lines, words",
            lineClass: "word-line"
        });

        if (!props.switchPage && props.clicked) {
            gsap.from(text.words, {
                y: "75%",
                // skewX: "-3",
                opacity: 0,
                duration: 1.5,
                delay: 0.5,
                stagger: 0.03,
            });


            gsap.to(description.current, {
                opacity: 1,
                duration: 0.5,
            });

        }
        if (props.switchPage && props.clicked) {
            // const text = new SplitText(description.current, {
            //     types: "lines, words",
            //     lineClass: "word-line"
            // });

            gsap.fromTo(description.current, {opacity: 1,},{
                opacity: 0,
                duration: 0.5,
            });

            // console.log(text.words);
            // gsap.to(text.words, {
            //     y: "75%",
            //     opacity: 0,
            //     // skewX: "3",
            //     duration: 1.0,
            //     // delay: 0.5,
            //     stagger: 0.03,
            // });
        }
    }, [props.switchPage, props.clicked])

    return (
        <div className='footer'>
            <h1 ref={description} className='footer__left-side'>

                We help innovative companies<br/>
                deliver value and win users' attention<br/>
                with 3d website development and<br/>
                interactive graphics production
                {/*We help tech companies and brands*/}
                {/*deliver value and win users' attention*/}
            </h1>
        </div>
    )
}